<template>
  <div class="question">
    <div class="main-container">
      <section class="app-main">
        <div class="container" :class="{ container_full:isFullscreen }" ref="container">
          <div class="main-background">
            <img style="width: 100%; height: 100%; object-fit: cover;" :src="branch['bg_img']"/>
          </div>
          <div class="title">
            <span style="font-size: 1.8rem">{{ question["name"] || "-" }}</span>
            <span class="ct">答题倒计时：</span>
            <span>{{ timeLable }}</span>
          </div>

          <!--  -->
          <div class="main">
            <div class="sub-title">
              <span style="font-size: 1.5rem">{{topic[activeKey]["name"]}}</span>
              <div class="progress">
                <i class="el-icon-time"></i>
                <span>当前进度:</span>
                <el-progress :percentage="percentage" :color="customColor" style="width: 10rem"></el-progress>
              </div>
            </div>
<!--           选择题-->
            <div class="choose" v-if="branch['choose'] && branch['choose']['title']">
              <div class="choose-title">
                {{ branch["choose"]["title"] }}
              </div>
              <!-- 多选 -->
              <div class="choose-option" v-if="branch['choose']['type'] == 'check'">
                <el-checkbox-group v-model="ckeckList">
                  <div class="option" v-for="(item, index) in branch['choose']['chooseOption']" :key="index">
                    <el-checkbox :label="item.value">{{item.name}}</el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>

              <!-- 单选 -->
              <div class="choose-option" v-if="branch['choose']['type'] == 'radio'">
                <el-radio-group v-model="radio" @change="submitAnswer(radio)">
                  <div class="option" v-for="(item, index) in branch['choose']['chooseOption']" :key="index">
                    <el-radio :label="item.value">{{ item.name }}</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
<!--操作题的题目-->
            <div class="operate" v-if="branch['type'] == 'operate'&&branch['ques_tips']">
                <div class="operate-title">
                  {{ branch["ques_tips"] }}
                </div>
            </div>
          </div>

          <!-- 按钮栏 -->
          <div class="toolbar">
            <div class="toolbar-content">
              <div class="btns">
                <el-button type="primary tips" v-if="type != 'opera'" @click="showTips">提示</el-button>

                <div class="right-btn">

                  <el-button v-if="nextShow" type="primary" :disabled="buttondisable" ref="next" @click="next">下一步</el-button>
                  <el-button type="primary" @click="onSubmit"  :disabled = "subDisabled" >提交</el-button>
                  <el-button type="primary" @click="reset(1)">重做</el-button>
                  <el-button type="primary" @click="goBack">返回</el-button>
                </div>
              </div>
            </div>
          </div>

          <!-- 场景切换 -->
          <div class="navs" v-show="branch['sceneChangeShow']">
            <div class="navs-header">场景导航</div>
            <div class="navs-list">
              <div v-for="(item, index) in topic[activeKey]['scene_change']" :key="index"  :class="item.class" :data-aa="item.class" @click="sceneChange(index)" >
                <img :src="item.img" />
                <span >{{item.name}}</span>
              </div>
            </div>
          </div>
          <!-- 放大镜 -->
          <div class="zoom" v-for="(item, index) in topic[activeKey]['zoomIn']"
              v-show="imgKey===item.scene"
              :style="{left: item['left'],top: item['top']}">
            <img src="../../assets/img/zoomIn.png" @click="showZoomImg(item.zoom)"/>
          </div>

<!--          <div class="smoke-tool" ref="smokeTool">-->
<!--            <img src="../../assets/img/wei8/yan.png" style="width: 100%"/>-->
<!--          </div>-->
        <!--消防室-->
          <fireController
              v-if="modalType.fireControllerShow"
              :visible="modalType.fireControllerShow"
              :parameter="modalType.modalData"
              :close="() => (modalType.fireControllerShow = false)"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @customOther="customOther"
              ref="fireController"
              class="fireController"
          ></fireController>

          <tools v-show="modalType.toolboxShow"
                 :branchKey="branchKey"
              @customPlay="play"
              @customPlay2="play2"
              @customPlay3="play3"
              @subToolAnswer="subToolAnswer"
              @customOther="customOther"
          ></tools>
          <div class="tool-other">
            <img v-show="branch.toolImgShow===item.index" v-for="(item) in branch.toolOther" :class="item.toolClass" :src="item['toolImg']" :style="item.toolStyle" :ref="item.ref"/>
          </div>
          <!--          提示显示-->
          <div class="tips_show" v-if="tipsShow&&branch['tips']">
            <div>
              <div>{{branch['tips']['correctAnswer']}}</div>
              <div>正确操作</div>
              <div v-for="(item, index) in branch['tips']['correctOperation']">{{item.step}}</div>
            </div>
          </div>

          <div class="fullscreen" v-if="!isFullscreen" @click="fullscreenClick">进入全屏</div>
        </div>
      </section>
    </div>
    <!--音频1 题目 -->
    <audio v-show="false" ref="audio" controls src="" preload="metadata" @play="handleMusicPlay" @ended="handleMusicEnded">
      <source src="" />
    </audio>
<!--    音频2 按钮、放大、放小-->
    <audio v-show="false" ref="audio2" controls src="" preload="metadata">
      <source src="" />
    </audio>
<!--    音频3 需要一直循环的声音-->
    <audio v-show="false" ref="audio3" controls src="" preload="metadata">
      <source src="" />
    </audio>
    <Baywindow></Baywindow>
  </div>
</template>

<script>
import fireController from "./components/fireController.vue";//消控室+疏散通道
import tools from "./components/tools.vue";
import {arraysAreEqual, fomatTime} from "../../util/arr.js";
import screenfull from "screenfull";
import Baywindow from "@/components/Baywindow.vue";

// 策略模式
let modalType = {
  'zoomImg': function (data) {
    this.zoomImgShow = true
    this.modalData = data;
  },
  'fireController': function (data) {
    this.fireControllerShow = true
    this.modalData = data;
  },
  'waterPump': function (data) {
    this.waterPumpShow = true
    this.modalData = data;
  },
  fireControllerShow: false,
  waterPumpShow: false,
  zoomImgShow: false,
  toolboxShow:false,
  modalData: {},
}

export default {
  data() {
    return {
      isFullscreen: true,
      question: null,//所有数据
      topic: [],//当前题目
      branch:[],//当前题目的第几小题
      // 进度条
      per_:20,
      percentage: 20,
      customColor: "#409eff",
      customColors: [{ color: "#f56c6c", percentage: 20 }],
      cities: [],
      ckeckList: [],
      radio: null,
      activeKey: 0,//当前题目的索引
      nextShow:false,//点击下一步出现的小提示
      branchKey: 0,//点击下一步, 当前题目的第几小题索引
      imgKey: 0,//第几张图
      buttondisable:false,//提交按钮, 是否点击
      tipsShow:false,//提示的显示
      submitAns:[],//每小题 提交的答案
      subDisabled:false,//提交按钮, 是否点击
      zoomImgShow:false,
      // 答案集合
      modalType,
      ansObj: {},
      init_question:{},
      type:'viewr',
      count:0,
      timer:null,
      timeLable:'00:00',
      apiId:'10008',
      totalScore:0,
    };
  },
  components: {
    fireController,
    tools,
    Baywindow
  },
  created() {
    let { question } = require(`../../api/wei${this.apiId}`);

    this.init_question = this.deepClone (question);
    this.question = this.deepClone (question);
    this.topic = this.question["topic"];//所有题目

    this.$set(this.topic,this.question["topic"])

    this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题

    this.$set(this.branch,this.topic[this.activeKey]['branch'][this.branchKey])
    this.nextQuestion();

    if(this.topic[this.activeKey]['ques_tips']&&this.topic[this.activeKey]['ques_tips'].length>1){
      this.nextShow=true;
    }
    screenfull.toggle();
    this.per_=100/this.topic.length;
    this.percentage=(this.per_*(this.activeKey+1));//进程

    this.type = this.$route.query.type
    this.count = this.$route.query.count
    if(this.type !== 'viewr'){
      this.timeCountSetInterVal()
    }
  },
  mounted() {
    // 监听全屏变化
    screenfull.onchange(() => {
      this.isFullscreen = screenfull.isFullscreen
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    this.timer=null;
    this.modalClose();
    next(true);
  },
  methods: {
    fullscreenClick(){// 手动全屏
      screenfull.toggle();
    },
    timeCountSetInterVal(){// 考试模式计时器
      if(this.timer) return
      this.timer = setInterval(() => {
        this.count = this.count - 1
        this.timeLable=fomatTime(this.count)
        if(this.count <= 0){
          clearInterval(this.timer)
          this.timer = null
          let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
          ids.push(this.apiId)
          sessionStorage.setItem('wids',JSON.stringify(ids))
          window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
          window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
          this.$router.replace("/result");
          this.reset();
        }
      },1000)
    },
    deepClone (source){
      var sourceCopy = source instanceof Array ? [] : {}
      for (var item in source) {
        sourceCopy[item] = typeof source[item] === 'object' ? this.deepClone(source[item]) : source[item]
      }
      return sourceCopy
    },
    play(val) {
      //题目
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`../../assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio.src = music;
        let { audio } = this.$refs;
        audio.load();
        audio.play();
      });
    },
    play2(val) {
      //一些按钮声音
      if (!val)  return;
      this.$refs.audio2.pause();
      let music = new Audio();
      // 这里获取播放路径
      music = require(`../../assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio2.src = music;
        let audio = this.$refs.audio2;
        audio.load();
        audio.play();
      });
    },
    play3(val,loop=false,play='play') {
      //可循环
      if(play==='stop'){
        this.$refs.audio3.pause()
      }
      if (!val)  return;
      let music = new Audio();
      // 这里获取播放路径
      music = require(`../../assets/audio/${val}`);

      this.$nextTick((res) => {
        this.$refs.audio3.src = music;
        let audio = this.$refs.audio3;
        audio.loop=loop;
        audio.load();
        audio.play();
      });
    },
    goBack() {
      this.$router.go(-1);
      this.play2('button.mp3');
    },
    async onSubmit() {
      //提交答案
      if(this.subDisabled){
        return false;
      }
      let ans = this.question.topic[this.activeKey]["ans"]
      // console.log('提交',this.submitAns[this.activeKey],'答案',ans)
      this.ansObj[this.activeKey] = arraysAreEqual(ans, this.submitAns[this.activeKey])

      if(this.ansObj[this.activeKey]){
        this.totalScore+=parseFloat(this.topic[this.activeKey]['score'])
      }
      let sorceObj = sessionStorage.getItem("sorceObj")? JSON.parse(sessionStorage.getItem("sorceObj")):[];
      sorceObj[this.apiId] = this.totalScore
      sessionStorage.setItem("sorceObj",JSON.stringify(sorceObj));
      console.log('分数',sorceObj)

      if (this.activeKey < this.question.topic.length - 1) {
        this.activeKey = this.activeKey + 1;//下一题
        if(this.topic[this.activeKey]['branch']&&this.topic[this.activeKey]['branch'][this.branchKey+1]){
          this.nextShow=true;
        }
        else{
          this.nextShow=false;
        }
      }
      else {
        this.play2('select.mp3');
        let ids = sessionStorage.getItem('wids') ? JSON.parse(sessionStorage.getItem('wids')) : []
        ids.push(this.apiId)
        sessionStorage.setItem('wids',JSON.stringify(ids))
        window.sessionStorage.setItem("topic", JSON.stringify(this.topic));
        window.sessionStorage.setItem("ansObj", JSON.stringify(this.ansObj));
        // console.log('做完啦',JSON.stringify(this.ansObj))
        setTimeout(() => {
          this.$router.replace("/result");
        }, 500);
        this.tipsShow=false;//提示
        this.modalType.fireControllerShow=false;//消控室
        this.modalType.waterPumpShow=false;//水泵房
        return true;
      }
      this.reset();
    },
    reset(type=0) {
      //重做
      //答案清空
      this.ckeckList = [];
      this.radio = null;
      this.submitAns=[];
      //索引情况
      this.branchKey=0;
      //按钮清除
      this.tipsShow=false;//提示
      this.modalType.fireControllerShow=false;//消控室
      this.modalType.waterPumpShow=false;//水泵房
      //场景图索引
      this.imgKey=0;
      this.subzoom={};//子组件参数
      //
      this.nextQuestion();
      this.play2('select.mp3');
      this.play3('','','stop');
      //重置需要清除改变的参数
      if(type===1){
        this.topic = this.deepClone(this.init_question["topic"]);
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey]//当前第几题的第几小题
      }
    },

    // ===================
    // // 全面屏
    fullscreen() {
      // 需要全屏显示的dom元素
      let dom = this.$el.querySelector(".container");
      // 调用全屏方法
      this.$fullscreen.enter(dom, {
        wrap: false,
        callback: (f) => {
          this.fullscreenFlag = f;
        },
      });
    },
    submitAnswer(value){
      //每小题选择的答案
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }
      if(this.branch.ansBranch){
        this.submitAns[this.activeKey][this.branch.ansBranch]=value;
      }
      else{
        this.submitAns[this.activeKey][0]=value;
      }
      this.play2('select.mp3');
    },
    handleMusicPlay(){
      if(this.nextShow){
        this.subDisabled = true;
      }
    },
    handleMusicEnded() {
      this.buttondisable=false;
      if(this.topic[this.activeKey]['branch'].length===1){
        this.subDisabled=false;
      }
    },
    next(event){
      //点击下一步, 下一题
      if(this.buttondisable){
        return false;
      }
      if(this.topic[this.activeKey]['branch'][this.branchKey+1]){
        this.branchKey++;
        this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
        this.nextShow=true;
        if(!this.submitAns[this.activeKey]){
          this.submitAns[this.activeKey]=[];
        }
        if(!this.submitAns[this.activeKey][this.branchKey]){
          this.submitAns[this.activeKey][this.branchKey]=0;
        }

        //有场景切换, 背景图不变
        let bg_=this.topic[this.activeKey]['scene_change'][this.imgKey]['bg']
        this.branch['bg_img']=bg_;
      }
      else{
        if(this.topic[this.activeKey]['branch'][this.branchKey+2]){
        }
        else{
          this.nextShow=false
        }
        this.subDisabled=false;
      }
      //如果有选择框,删除选择框
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      for(let i=0;i<box_frame_bg.length;i++){
        box_frame_bg[i].classList.remove('box_frame_bg')
      }

      this.play2('button.mp3')
    },
    nextQuestion(){
      //提交后的 ,下一题的处理,如题目播放, 是否随机题目
      this.percentage=(this.per_*(this.activeKey+1));//进程
      this.branchKey=0;
      this.imgKey=0;
      this.branch=this.topic[this.activeKey]['branch'][this.branchKey];
      this.play(this.topic[this.activeKey]["audio"])
      if(this.branch['audio']){
        this.play3(this.branch['audio'],true)
      }

      if(this.topic[this.activeKey]['branch'][this.branchKey+1]) {
        this.nextShow = true;
      }
      //如果有场景切换
      if(this.branch['sceneChangeShow']){
        this.sceneChange(this.imgKey);
      }
    },
    modalClose(){
      //关闭所有弹窗和提示
      this.tipsShow=false;//提示
      this.modalType.fireControllerShow=false;//消控室
      this.modalType.waterPumpShow=false;//水泵房
      this.modalType.zoomImgShow=false;//图片
      this.modalType.toolboxShow=false;//工具箱
    },
    showTips(){//显示提示
      if(this.tipsShow==false){
        this.tipsShow=true;
      }
      else{
        this.tipsShow=false;
      }
      this.play2('button.mp3');
    },
    sceneChange(index_){
      //场景小图切换
      this.imgKey=index_;
      let scene_change=this.topic[this.activeKey]['scene_change'][index_];
      this.branch ['bg_img']=scene_change['bg']

      if(document.querySelector('.nav.actived')){
        document.querySelector('.nav.actived').classList.remove('actived');
      }
      if(document.querySelectorAll('.nav')[index_]){
        document.querySelectorAll('.nav')[index_].classList.add('actived')
      }
      var box_frame_bg=document.querySelectorAll('.box_frame_bg');
      if(box_frame_bg){
        for(let i=0;i<box_frame_bg.length;i++){
          box_frame_bg[i].classList.remove('box_frame_bg')
        }
      }
      // if (this.activeKey===3&&this.submitAns[this.activeKey]&&this.submitAns[this.activeKey].length>0){
      //   //10-4-1, 点击水流后, 切换场景更改背景音乐
      //   let url_=this.branch['zoomIn'][this.imgKey]['zoom']['audio'];
      //   this.play3(url_,true)
      // }
      //如果有工具箱出现
      if(scene_change.toolboxShow){
        this.modalType.toolboxShow=true;
      }
      else{
        this.modalType.toolboxShow=false;
      }
    },
    operateAnswer(val,e){
      //操作题的答案,
      //1 阴影选择框答案,点击后加一层浮于表面的背景色 box_frame_bg 判断有class就移动, 没有就加
      //2 弹窗组件答案
      var this_=e.currentTarget;
      if(this_){
        if(this_.classList.contains('box_frame_bg')){
          this_.classList.remove('box_frame_bg')
        }
        else{
          this_.classList.add('box_frame_bg')
        }
      }
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }
      if(!this.submitAns[this.activeKey][this.branchKey]){
        this.submitAns[this.activeKey][this.branchKey]=val;
      }
      else{
        this.submitAns[this.activeKey].splice(this.branchKey);
      }
    },
    showZoomImg(zoom){
      //点击放大镜
      zoom.branchKey=this.branchKey;
      this.modalType[zoom['modalType']](zoom)
      this.play2('magnifier-da.mp3');
    },
    subAnswer(value){
      //子组件, 提交答案
      let branchKey=value[0];//
      let val=value[1];
      if(!this.submitAns[this.activeKey]){
        this.submitAns[this.activeKey]=[];
      }

      // this.submitAns[this.activeKey][branchKey]=val;
      if(typeof val==='object'){
        let this_=this;
        if(!this_.submitAns[this_.activeKey][branchKey]){
          this_.submitAns[this_.activeKey][branchKey]=[];
        }
        Object.keys(val).forEach(function (k,v) {
          this_.submitAns[this_.activeKey][branchKey][k]=val[k];
        })
      }
      else{
        if(!this.submitAns[this.activeKey][branchKey]){
          this.submitAns[this.activeKey][branchKey]=0;
        }
        this.submitAns[this.activeKey][branchKey]=val;
      }

      // console.log('提交答案',branchKey,this.submitAns[this.activeKey])
    },
    subToolAnswer(value,branch=-1,next=0){
      // console.log('subToolAnswer',value,this.branchKey)
      //工具箱提交答案
      let this_=this;
      if (this_.branch['tool'+value]&&(this_.branch['tool'+value]['submit'])) {
        let sub=this_.branch['tool'+value]['submit'];
        if ((sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subAnswer([sub['branch'], sub['ans']])
        }
        else if(sub['equal'] === 4&&sub['branch'+this.branchKey]){
          var sub_=sub['branch'+this.branchKey]
          this.subAnswer([this.branchKey, sub_.ans])
          this_.subUpdate(this_.branch['tool'+value]['update'+this.branchKey])
          if(sub_.next){
            this.branchKey=sub_.next;
          }
          return true;
        }
      }
      if(this_.branch['tool'+value]['update']){
        this_.subUpdate(this_.branch['tool'+value]['update'])
      }
    },
    customOther( sy,sub,update_=[]){
      console.log('customOther', sy, sub,update_, this.branchKey)
      //有答案提交
      if (sub['ans']) {
        if ((sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subAnswer([sub['branch'], sub['ans']])
        }
        else if(sub['equal']===4&&sub['branch'+this.branchKey]){
          sub=sub['branch'+this.branchKey]
          this.subAnswer([this.branchKey, sub.ans])
        }
      }
      //有参数变化
      if(update_.length>0||Object.keys(update_).length){
        if ((sub['equal'] === 1 && this.branchKey === sub['branch']) || (sub['equal'] === 3)) {
          this.subUpdate(update_);
        }
        else if(sub['equal']===4){
          this.subUpdate(update_)
        }
      }
      //小题索引改变
      if (sub['next'] && sub['next'] > 0) {
        this.branchKey = sub['next'];
        this.modalType.modalData.branchKey=this.branchKey;
      }
    },
    subUpdate(data){
      //子组件操作修改其他放大镜下的参数
      console.log('subUpdate',data)
      let this_=this;
      if(data.zoom&&data.zoom.length>0){
        Object.keys(data.zoom).forEach((k,v)=>{
          let up_=data.zoom[k];
          this_.topic[this.activeKey]['zoomIn'][up_.index]['zoom']=this_.arrcover(up_.up,this.topic[this.activeKey]['zoomIn'][up_.index]['zoom'])
        })
      }
      if(data.scene_change&&data.scene_change.length>0){
        Object.keys(data.scene_change).forEach((k,v)=>{
          let up_=data.scene_change[k];
          this_.topic[this.activeKey]['scene_change'][up_.index]=this_.arrcover(up_.up,this_.topic[this.activeKey]['scene_change'][up_.index])
        })
      }
      if(data.audio){
        if(typeof data.audio==='object'){
          Object.keys(data.audio).forEach(function (k,v) {
            this_.play3(data.audio[0]['url'],data.audio[0]['loop'],data.audio[0]['play'])
          })
        }
        else{
          this.play3(data.audio.url,data.audio.type)
        }
      }
      if(data.toolOther){
        Object.keys(data.toolOther).forEach((k,v)=>{
          let up_=data.toolOther[k];
          this_.branch['toolOther'][up_.index]=this_.arrcover(up_.up,this_.branch['toolOther'][up_.index])
        })
      }
      if(data.delay){
        setTimeout(function () {
          this_.$nextTick(() => {
            this_.subUpdate(data.delay)
          })
        },data.delay.time);
      }
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>
<style>
.el-radio__label{
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
<style lang="less" scoped>
.question {
  position: relative;
  height: 100%;
  width: 100%;
  // ======
}
// .main-container {
//   width: 100%;
//   height: 100%;
//   transition: margin-left 0.28s;
//   margin-left: 0;
//   position: relative;
//   background: #f8f8f8;
// }
.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: 0;
  position: relative;
  background: #f8f8f8;
}
.app-main {
  position: relative;
}
.container_full{ // 全屏下
  width: 100vw !important;
  height: 100vh !important;
  margin: 0 auto !important;
  position: relative !important;
}
.container {
  // 非全屏下
  width: 120rem;
  height: 67.5rem;
  margin: 0 auto;
  position: relative;
  .main-background {
    position: absolute;
    inset: 0 0 0 0;
    z-index: 0;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .title {
    height: 2.5rem;
    background-color: #409eff;
    line-height: 2.5rem;
    color: #fff;
    font-weight: 500;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 2rem;
    .ct {
      margin-left: auto;
    }
  }
  .main {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-attachment: fixed;
    .sub-title {
      height: 2.5rem;
      background-color: #fff;
      padding: 0 1.25rem;
      position: absolute;
      z-index: 8;
      left: 0;
      top: 2.5rem;
      width: 100%;
      font-size: 1.5rem;
      .progress {
        font-size: 0.875rem;
        span {
          font-size: 0.8125rem;
          margin: 0 0.3125rem;
        }
      }
    }
    // 选这题
    .choose {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .choose-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

    .operate {
      position: absolute;
      left: 1.25rem;
      top: 6.25rem;
      min-width: 12.5rem;
      max-width: 21.875rem;
      background-color: #409eff;
      z-index: 99999;
      border-radius: 0.625rem;
      box-shadow: 1px 1px 5px #ccc;
      box-sizing: border-box;
      .operate-title {
        color: #fff;
        padding: 0.625rem;
        font-size: 1.5rem;
      }
      .choose-option {
        background-color: #fff;
        padding: 1.25rem 0.625rem;
        border-radius: 0 0 0.625rem 0.625rem;
        .option {
          margin-bottom: 1.25rem;
          font-size: 1.5rem;
        }
      }
    }

  }

  //   按钮
  .toolbar {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 2.5rem;
    z-index: 9999;
  }
  .toolbar-content {
    margin: 0 12.5rem;
    .right-btn {
      margin-left: auto;
    }
    button {
      padding: 0.625rem 1.875rem;
      color: #fff;
      font-size: 16px;
      margin: 0 1.875rem;
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }
  .toolbar-content .btns,
  .toolbar-content .right-btn {
    display: flex;
    align-items: center;
  }
  .toolbar-content .btns {
    position: absolute;
    z-index: 9999;
    left: 6.25rem;
    right: 6.25rem;
    bottom: 0;
  }

  .navs {
    position: absolute;
    right: 2.5rem;
    bottom: 10rem;
    z-index: 334;
    .navs-header {
      background-color: #409eff;
      border-radius: 0.625rem 0.625rem 0 0;
      color: #fff;
      font-size: 1rem;
      padding: 0.625rem 0.9375rem;
    }
    .navs-list {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      background-color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0.9375rem 0.9375rem;
      border-radius: 0 0 0.625rem 0.625rem;
      //height: 9.375rem;
      //overflow-y: auto;
      .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 13.125rem;
        margin-bottom: 0.9375rem;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #fff;
        img {
          width: 5rem;
          height: 3.125rem;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 0.3125rem 0 0 0.3125rem;
        }
        span {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          text-align: center;
        }
      }
      .actived {
        border: 1px solid #409eff;
        color: #409eff;
        border-radius: 0.3125rem;
        font-weight: 500;
      }
      .nav:last-child {
        margin-bottom: 0;
      }
    }
  }
  //可以选择的框
  .box_frame{
    border:2px solid blue;
    position: absolute;
  }
  .box_frame_bg{
    background: #6b82f5;
    opacity: 0.7;
  }
  //提示显示
  .tips_show{
    z-index: 2009;
    border-radius: 0.3125rem;
    border: 0.3125rem solid #ccc;
    position: absolute;
    background: #fdfdfd;
    width: 50%;
    top: 20%;
    left: 25%;
    padding: 1rem;
    font-size:1.15rem ;
    div{
      line-height: 35px ;
      //text-indent: 1rem;
    }
  }
  .toolboxs{
    position: absolute;
    top: 5rem;
    right: 0rem;
  }
  .fullscreen {
    position: absolute;
    bottom: -3.125rem;
  }
}

.container .main .sub-title .progress,
.container .main .sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zoom {
  position: absolute;
  cursor: pointer;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}
/deep/.zoomOut{
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
}
/deep/.el-dialog__wrapper{
  z-index: 2000 !important;
}
.smoke-tool{
  position: absolute;
  right: 45.6%;
  top: 16%;
  width: 12rem;
  display: none;
}
.smoke-tool-block{
  display: block;
}
</style>
